import {guardConfigDefaultFieldValues} from '@/views/domain/GuardConfiguration';

export default function getDefaultDomainConfig() {
    return {
        id: null,
        name: '',
        sourceConfig: {
            full_url: "",
            redirect: false,
            no_www: false,
            is_bypass_acme: false,
        },
        sslConfig:{
            enabled: false,
            isAutoGenerateCertificate: false,
            is_show: false,
        },
        alias_redirect: false,
        aliases: [],
        strategies: [],
        shadows: [],
        cacheConfig: {
          cache_type: "static",
          nocache: []
        },
        guardConfig: guardConfigDefaultFieldValues(),
        proxy_host: null
    };
}
